<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex>
        <view-title />
      </v-flex>
      <v-spacer />
      <v-flex shrink>
        <div class="FAB-Extended-PrimaryColor-Enabled">
          <v-btn type="submit" color="primary" @click="dialog.state = true">
            <v-icon left dark>mdi-plus</v-icon>add new group
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6>
        <v-text-field
          solo
          label="Search all Groups"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-data-table :headers="headers" :items="sectors" :search="search">
          <template v-slot:items="props">
            <tr @click="goToSector(props.item.id)" class="clickable">
              <td>{{ props.item.name }}</td>
              <td>{{ countryMapping[props.item.country] }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <sector-dialog :dialog.sync="dialog.state" @updated="init" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import sectorDialog from './sectorDialog';

export default {
  components: {
    sectorDialog,
  },
  data: () => ({
    sectors: [],
    search: '',
    dialog: {
      state: false,
      countryListing: [
        { text: 'Canada', value: 'CA' },
        { text: 'United States', value: 'US' },
      ],
    },
    headers: [
      {
        text: 'Groups',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Country',
        align: 'left',
        value: 'country',
      },
    ],
  }),
  computed: {
    countryMapping() {
      const map = {};
      this.dialog.countryListing.forEach((country) => {
        map[country.value] = country.text;
      });
      return map;
    },
  },
  methods: {
    ...mapActions('sectors', ['fetchSectors']),
    async getSectors() {
      const sectors = await this.fetchSectors();
      return sectors.map((sector) => {
        const [name, country] = sector.name.split('/');
        return {
          ...sector,
          name,
          country,
        };
      });
    },

    goToSector(id) {
      this.$router.push({ name: 'sector_management', params: { sector_id: id } });
    },

    async init() {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 0,
        title: 'Group Management',
        to: { name: 'sectors' },
      });

      this.$store.commit('adminPanel/setLoading', true);
      this.sectors = await this.getSectors();
      this.$store.commit('adminPanel/setLoading', false);
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style></style>
