import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(VFlex, [_c("view-title")], 1),
          _c(VSpacer),
          _c(VFlex, { attrs: { shrink: "" } }, [
            _c(
              "div",
              { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
              [
                _c(
                  VBtn,
                  {
                    attrs: { type: "submit", color: "primary" },
                    on: {
                      click: function($event) {
                        _vm.dialog.state = true
                      }
                    }
                  },
                  [
                    _c(VIcon, { attrs: { left: "", dark: "" } }, [
                      _vm._v("mdi-plus")
                    ]),
                    _vm._v("add new group\n        ")
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        VLayout,
        [
          _c(
            VFlex,
            { attrs: { xs6: "" } },
            [
              _c(VTextField, {
                attrs: {
                  solo: "",
                  label: "Search all Groups",
                  "prepend-inner-icon": "mdi-magnify"
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(VDataTable, {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.sectors,
                  search: _vm.search
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "clickable",
                            on: {
                              click: function($event) {
                                return _vm.goToSector(props.item.id)
                              }
                            }
                          },
                          [
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.countryMapping[props.item.country])
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("sector-dialog", {
        attrs: { dialog: _vm.dialog.state },
        on: {
          "update:dialog": function($event) {
            return _vm.$set(_vm.dialog, "state", $event)
          },
          updated: _vm.init
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }